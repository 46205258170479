<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="addnewinfo" :rules="addnewinfo_rules" ref="addnewinfo" label-width="110px"
						label-position="left" class="demo-ruleForm" style="padding-left: 20px;">
						<el-form-item label="账号" prop="username">
							<el-input v-model="addnewinfo.username" style="width: 30%;" placeholder="请输入账号"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input v-model="addnewinfo.password" style="width: 30%;" placeholder="请输入密码/为空表示不修改"></el-input>
						</el-form-item>
						<el-form-item label="联系电话">
							<el-input v-model="addnewinfo.phone" style="width: 30%;" placeholder="请输入联系电话"></el-input>
						</el-form-item>
						<el-form-item label="联系人">
							<el-input v-model="addnewinfo.nickname" style="width: 30%;" placeholder="请输入联系人"></el-input>
						</el-form-item>
						<el-form-item label="所属保险公司">
							<el-select v-model="selectcompny" :multiple="true" placeholder="请选择" style="width: 30%;">
								<el-option v-for="item in options" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="拥有权限">
							<el-tree :data="data" show-checkbox node-key="id" :default-expand-all="true" ref="mytree" :props="defaultProps" @check="handleCheckChange">
							</el-tree>
						</el-form-item>
						<el-form-item label="头像">
							<el-upload class="avatar-uploader" :action="baseurl+'/admin/common/upload'" :headers="{
									token:$store.state.userinfo.token
								}" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>

						<el-form-item label="是否启用" required style="display: flex;align-items: center;">
							<el-switch v-model="addnewinfo.status" active-color="#409EFF" active-value='1'
								inactive-value='0'>
							</el-switch>
						</el-form-item>
						<el-form-item label="排序">
							<el-input v-model="addnewinfo.weigh" type="number" style="width: 10%;"
								placeholder="1"></el-input>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="setaddnew">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				baseurl: "",
				addnewinfo: {
					username:'',
					password:'',
					phone:'',
					nickname:'',
					baoxiangongsi_ids:'',
					avatar:'',
					status:'1',
					weigh:1,
					menu_ids:''
				},
				addnewinfo_rules: {
					username: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				options:[],
				listdata: [],
				imageUrl: '',
				data: [],
				defaultProps: {
					children: 'chlidren',
					label: 'name'
				},
				selectmenu:[],
				id:'',
				selectcompny:''
			}
		},
		created() {
			this.baseurl = this.$URL
			this.id = this.$route.query.id
			this.getinfo()
			this.getcompany()
			
		},
		methods: {
			getinfo(){
				this.$post({
					url:'/admin/admin/detail/id/'+this.id,
					params:{
						id:this.id			
					}
				}).then((res)=>{	
					this.selectcompny = res.baoxiangongsi_ids.split(',')
					this.selectmenu = res.menu_ids.split(',')
					this.imageUrl = this.$URL + res.avatar
					this.addnewinfo = res
					this.addnewinfo.password=''
					this.getfatherlist()
				})
				
			},
			handleCheckChange(e,v){
				this.selectmenu = v.checkedKeys
			},
			getcompany(){
				this.$get({
					url:'/admin/ins_company/index',
					params:{
						page:1,
						page_size:999			
					}
				}).then((res)=>{					
					this.options = res.data
				})
			},
			getfatherlist() {
				this.$get({
					url: '/admin/system_menu/index',
					params: {

					}
				}).then((res) => {
					this.data = res
					console.log(this.selectmenu)
					this.$refs.mytree.setCheckedKeys(this.selectmenu)
				})
			},
			submitForm() {
				this.$refs.addnewinfo.validate((valid) => {
					if (valid) {
						this.addnewinfo.menu_ids = this.selectmenu.join(',')
						this.addnewinfo.baoxiangongsi_ids = this.selectcompny.join(',')
						delete this.addnewinfo.createtime
						delete this.addnewinfo.updatetime
						
						this.$post({
							url: '/admin/admin/edit/id/'+this.id,
							params: this.addnewinfo
						}).then((res) => {
							this.$message.success('修改成功')
							this.$router.push('/system/account')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			handleAvatarSuccess(res, file) {
				this.addnewinfo.avatar = res.data.filename
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			setaddnew() {
				this.$router.push('/system/account')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>